.footer-component {
    margin-top: 180px;
    padding: 56px 20px 24px;

    @media (max-width: 991px) {
        margin-top: 60px;
    }

    .txt-right {
        @media(max-width: 991px) {
            text-align: left;
        }
    }

    .top-divider {
        padding-top: 36px;
        margin-top: 36px;
        border-top: 1px solid var(--color-white);
    }
}