.header-component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999999999;

    >.container>.row>.d-flex {
        width: 100%;
    }

    .top {
        padding: 20px 0;

        .logo {
            padding-right: 25px;
            border-right: 1px solid var(--color-primary-contrast);

            @media(max-width: 991px) {
                border: 0;
                padding: 0;
            }
        }

        .menu {
            @media(max-width: 991px) {
                position: fixed;
                top: 0;
                right: -400px;
                width: 300px;
                height: 100vh;
                flex-direction: column;
                background-color: var(--color-primary);
                align-items: center;
                padding-top: 70px;
                box-shadow: 0 0 50px rgba(0, 0, 0, .2);
                transition: .3s;

                &.active {
                    right: 0;
                    z-index: 9999998;
                }
            }
        }
    }

    .menu-controller {
        position: relative;
        z-index: 9999999;

        &.active {
            position: absolute;
            top: 15px;
            right: 15px;
        }

        img {
            width: 36px;
            height: 36px;
        }
    }

    @media(max-width: 991px) {
        .container {
            padding: 0 10px;
        }
    }

    + .spacer {
        height: 100px;
    }
}