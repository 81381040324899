body {
  opacity: 0;
  transition: .2s;
  background: white;
}

::-webkit-scrollbar {
  width: 12px;
  height: 0;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: #000;
}

::-webkit-scrollbar-thumb:active {
  background: var(--color-primary-shade);
}

::-webkit-scrollbar-track {
  background: #eaeaea;
}

::-webkit-scrollbar-track:hover {
  background: #eaeaea;
}

::-webkit-scrollbar-track:active {
  background: #eaeaea;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.App {
  overflow: hidden;
}

.schema {
  display: none;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  letter-spacing: -0.025em;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
img {
  display: block;
}

a {
  text-decoration: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

b,
strong {
  font-weight: 600;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.clear {
  clear: both;
}

body {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
}

button,
input,
textarea {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: -0.025em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  outline: none !important;
  box-sizing: border-box;
}

button {
  &:disabled {
    opacity: 0.5 !important;
  }
}

.hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 10px;
  width: 10px;
  overflow: hidden;
}

.inactive {
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.suspense {
  width: 100vw;
  height: 100vh;
}

body>iframe {
  pointer-events: none !important;
}

h1#mainTitle {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}


.btn {
  padding: 9px 16px;
  font-size: 16px;
  height: 42px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s;
  font-weight: 500;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: fit-content;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    filter: brightness(0.8);
    text-decoration: none;
  }
}

.btn-large {
  padding: 8px 24px;
  height: 48px;
}

.btn-full {
  width: 100%;
}

.no-border {
  border-color: transparent !important;
}

.txt-uppercase {
  text-transform: uppercase;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.txt-center {
  text-align: center;
}

body {
  opacity: 1 !important;
}

#modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .content {
    width: 500px;
    max-width: 90%;
    background-color: white;
    padding: 50px;
    border-radius: 5px;
    text-align: center;

    h3 {
      font-size: 32px;
      font-weight: bold;
      color: var(--ion-text-color);
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      color: var(--ion-text-color);
      font-weight: 500;
      margin-bottom: 50px;
    }

    button {
      width: 100%;
      height: 44px;
      background: var(--color-primary);
      border-radius: 10px;
      display: block;
      margin: 0 auto;
      color: var(--color-primary-contrast);
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

$colors: (
  primary: #FFDD00,
  primary-shade: #FCC00E,
  primary-tint: #fcea7a,
  primary-contrast: #1D1D1B,
  secondary: #F39200,
  secondary-shade: #E84E0F,
  secondary-tint: #fdae37,
  secondary-contrast: #1D1D1B,
  success: #559A4F,
  success-shade: #2e8026,
  success-tint: #83d17c,
  success-contrast: #FFFFFF,
  danger: #D13330,
  danger-shade: #491D1D,
  danger-tint: #EF4743,
  danger-contrast: #FFFFFF,
  warning: #faa719,
  warning-shade: #8d5900,
  warning-tint: #ffc766,
  warning-contrast: #FFFFFF,
  info: #0284AD,
  info-shade: #006483,
  info-tint: #13a1cc,
  info-contrast: #FFFFFF,
  light-100: #706F6F,
  light-200: #DADADA,
  black: #000000,
  white: #FFFFFF
);

$displays: (
  "block",
  "flex",
  "inline",
  "inline-block",
  "inline-flex",
  "grid",
  "none"
);

$alignItems: (
  "center",
  "flex-start",
  "flex-end"
);

$justifyContents: (
  "center",
  "flex-start",
  "flex-end",
  "space-around",
  "space-between",
  "space-evenly"
);

$gaps: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  55,
  60
);

$tagTexts: (
  h1: (64px, 700, 70px, 28px, 700, 35px),
  h2: (36px, 700, 44px, 24px, 600, 30px),
  h3: (24px, 600, 30px, 20px, 600, 24px),
  h4: (18px, 500, 22px, 16px, 500, 20px),
  h5: (16px, 500, 20px, 12px, 500, 15px),
  p: (16px, 400, 32px, 14px, 400, 24px),
  li: (18px, 300, 32px, 16px, 400, 24px)
);

$classTexts: (
  p1: (18px, 300, 32px, 16px, 300, 24px),
  p2: (16px, 400, 32px, 14px, 400, 24px),
  p3: (12px, 400, 16px, 10px, 400, 16px)
);

$textWeights: (
  300,
  400,
  500,
  600,
  700
);

$defaultMultiplier: (
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10
);

.flex-direction-row {
  flex-direction: row;
}

.flex-direction-column {
  flex-direction: column;
}

@media (min-width: 768px) {
  .flex-direction-sm-row {
    flex-direction: row;
  }

  .flex-direction-sm-column {
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .flex-direction-md-row {
    flex-direction: row;
  }

  .flex-direction-md-column {
    flex-direction: column;
  }
}

@media (min-width: 1290px) {
  .flex-direction-lg-row {
    flex-direction: row;
  }

  .flex-direction-lg-column {
    flex-direction: column;
  }
}

@each $weight in $textWeights {
  .txt-weight-#{$weight} {
    font-weight: $weight  !important;
  }
}

@each $width in $defaultMultiplier {
  .border-#{$width} {
    border: #{$width}px solid;

  }
}

@each $width in $defaultMultiplier {
  .radius-#{$width} {
    border-radius: #{$width}px;

  }
}

@function pow($number, $exponent) {
  $value: 1;

  @if $exponent >0 {
    @for $i from 1 through $exponent {
      $value: $value * $number;
    }
  }

  @return $value;
}

/******************************
* Padding class
*/

@each $multiplier in $defaultMultiplier {
  .p-#{$multiplier} {
    padding: pow(2, $multiplier) + px;
  }

  .pr-#{$multiplier} {
    padding-right: pow(2, $multiplier) + px;
  }

  .pl-#{$multiplier} {
    padding-left: pow(2, $multiplier) + px;
  }

  .pt-#{$multiplier} {
    padding-top: pow(2, $multiplier) + px;
  }

  .pb-#{$multiplier} {
    padding-bottom: pow(2, $multiplier) + px;
  }
}

/******************************
* Margin class
*/

@each $multiplier in $defaultMultiplier {
  .m-#{$multiplier} {
    margin: pow(2, $multiplier) + px;
  }

  .mr-#{$multiplier} {
    margin-right: pow(2, $multiplier) + px;
  }

  .ml-#{$multiplier} {
    margin-left: pow(2, $multiplier) + px;
  }

  .mt-#{$multiplier} {
    margin-top: pow(2, $multiplier) + px;
  }

  .mb-#{$multiplier} {
    margin-bottom: pow(2, $multiplier) + px;
  }
}

@each $multiplier in $defaultMultiplier {
  @media (min-width: 768px) {
    .m-sm-#{$multiplier} {
      margin: pow(2, $multiplier) + px;
    }

    .mr-sm-#{$multiplier} {
      margin-right: pow(2, $multiplier) + px;
    }

    .ml-sm-#{$multiplier} {
      margin-left: pow(2, $multiplier) + px;
    }

    .mt-sm-#{$multiplier} {
      margin-top: pow(2, $multiplier) + px;
    }

    .mb-sm-#{$multiplier} {
      margin-bottom: pow(2, $multiplier) + px;
    }
  }
}

@each $multiplier in $defaultMultiplier {
  @media (min-width: 992px) {
    .m-md-#{$multiplier} {
      margin: pow(2, $multiplier) + px;
    }

    .mr-md-#{$multiplier} {
      margin-right: pow(2, $multiplier) + px;
    }

    .ml-md-#{$multiplier} {
      margin-left: pow(2, $multiplier) + px;
    }

    .mt-md-#{$multiplier} {
      margin-top: pow(2, $multiplier) + px;
    }

    .mb-md-#{$multiplier} {
      margin-bottom: pow(2, $multiplier) + px;
    }
  }
}

@each $multiplier in $defaultMultiplier {
  @media (min-width: 1290px) {
    .m-lg-#{$multiplier} {
      margin: pow(2, $multiplier) + px;
    }

    .mr-lg-#{$multiplier} {
      margin-right: pow(2, $multiplier) + px;
    }

    .ml-lg-#{$multiplier} {
      margin-left: pow(2, $multiplier) + px;
    }

    .mt-lg-#{$multiplier} {
      margin-top: pow(2, $multiplier) + px;
    }

    .mb-lg-#{$multiplier} {
      margin-bottom: pow(2, $multiplier) + px;
    }
  }
}



/******************************
* Gap class
*/

@each $gap in $gaps {
  .gap-#{$gap} {
    gap: #{$gap}px;
  }
}

@each $gap in $gaps {
  @media (min-width: 768px) {
    .gap-sm-#{$gap} {
      gap: #{$gap}px;
    }
  }
}

@each $gap in $gaps {
  @media (min-width: 992px) {
    .gap-md-#{$gap} {
      gap: #{$gap}px;
    }
  }
}

@each $gap in $gaps {
  @media (min-width: 1290px) {
    .gap-lg-#{$gap} {
      gap: #{$gap}px;
    }
  }
}

/******************************
* Display class
*/

@each $display in $displays {
  .d-#{$display} {
    display: #{$display};
  }
}

@each $display in $displays {
  @media (min-width: 768px) {
    .d-sm-#{$display} {
      display: #{$display};
    }
  }
}

@each $display in $displays {
  @media (min-width: 992px) {
    .d-md-#{$display} {
      display: #{$display};
    }
  }
}

@each $display in $displays {
  @media (min-width: 1290px) {
    .d-lg-#{$display} {
      display: #{$display};
    }
  }
}

/******************************
* Align class
*/
@each $alignItem in $alignItems {
  .align-#{$alignItem} {
    align-items: #{$alignItem};
  }
}

@each $alignItem in $alignItems {
  @media (min-width: 768px) {
    .align-sm-#{$alignItem} {
      align-items: #{$alignItem};
    }
  }
}

@each $alignItem in $alignItems {
  @media (min-width: 992px) {
    .align-md-#{$alignItem} {
      align-items: #{$alignItem};
    }
  }
}

@each $alignItem in $alignItems {
  @media (min-width: 1290px) {
    .align-lg-#{$alignItem} {
      align-items: #{$alignItem};
    }
  }
}

/******************************
* Justify class
*/

@each $justifyContent in $justifyContents {
  .justify-#{$justifyContent} {
    justify-content: #{$justifyContent};
  }
}

@each $justifyContent in $justifyContents {
  @media (min-width: 768px) {
    .justify-sm-#{$justifyContent} {
      justify-content: #{$justifyContent};
    }
  }
}

@each $justifyContent in $justifyContents {
  @media (min-width: 992px) {
    .justify-md-#{$justifyContent} {
      justify-content: #{$justifyContent};
    }
  }
}

@each $justifyContent in $justifyContents {
  @media (min-width: 1290px) {
    .justify-lg-#{$justifyContent} {
      justify-content: #{$justifyContent};
    }
  }
}

@each $class,
$info in $tagTexts {

  #{$class},
  .#{$class} {
    font-family: 'Nunito';
    font-style: normal;
    font-size: nth($info, 1);
    font-weight: nth($info, 2);
    line-height: nth($info, 3);

    @media(max-width: 991px) {
      font-size: nth($info, 4);
      font-weight: nth($info, 5);
      line-height: nth($info, 6);
    }
  }
}

@each $class,
$info in $classTexts {
  .#{$class} {
    font-family: 'Nunito';
    font-style: normal;
    font-size: nth($info, 1);
    font-weight: nth($info, 2);
    line-height: nth($info, 3);

    @media(max-width: 991px) {
      font-size: nth($info, 4);
      font-weight: nth($info, 5);
      line-height: nth($info, 6);
    }
  }
}

@each $name,
$color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }

  .txt-#{$name} {
    color: $color;
  }

  .txt-#{$name}__hover {
    &:hover {
      color: $color;
    }
  }

  .border-#{$name} {
    border-color: $color;
  }

  :root {
    --color-#{$name}: #{$color};
  }
}