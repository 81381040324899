.link {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    background-color: #25d366;
    border-radius: 50%;
    padding: 5px 5px 5px 7px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    z-index: 999;
}

.link::before {
    content: "fale conosco";
    position: absolute;
    width: 150px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -160px;
    font-size: 16px;
    top: 15px;
    background-color: #efefef;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    color: #2c4760;
    border-radius: 5px;
    font-weight: 500;
    opacity: 0;
    pointer-events: none;
    transition: .2s;
}

.link:hover::before {
    opacity: 1;
}

.link::after {
    content: "precisa de ajuda?";
    position: absolute;
    width: 150px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -160px;
    font-size: 16px;
    top: 15px;
    background-color: #efefef;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
    color: #2c4760;
    border-radius: 5px;
    font-weight: 500;
    opacity: 0;
    pointer-events: none;
    transition: .2s;
    animation: hide 10s;
}

.imagem {
    width: 48px;
    height: 48px;
}

@keyframes hide {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}