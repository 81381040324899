:root {
    --primary: var(--color-primary);
    --accept-color: var(--color-success);
    --text-color: var(--color-primary-contrast);
}
.hide {
    opacity: 0 !important;
    pointer-events: none !important;
}
.accept-cookies {
    z-index: 99999;
    background: var(--primary);
    color: var(--text-color);
    width: auto;
    max-width: 390px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 40px;
    border-radius: 2px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    opacity: 1;
    transition: 0.3s;
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.3);
}
.accept-cookies_header {
    margin: 20px 0;
}
.accept-cookies_buttons button {
    padding: 10px 20px;
    cursor: pointer;
}
.accept-cookies_buttons .adjust {
    background: 0 0;
    border-bottom: 1px solid var(--text-color);
    color: var(--text-color);
    padding: 0;
    margin-right: 10px;
    border: none;
}
.accept-cookies_buttons .accept {
    background-color: var(--text-color);
    color: var(--primary);
    font-weight: 700;
    border: none;
}
.adjust-cookies-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9999;
    text-align: left;
}
p.accept-cookies_message {
    color: var(--text-color);
    font-size: 14px;
    text-align: left;
    line-height: 150%;
}
.adjust-cookies-modal p {
    font-size: 16px;
    line-height: 24px;
}
.adjust-cookies-modal_content {
    background: #fff;
    border-radius: 10px;
    padding: 40px;
    width: 50%;
    height: auto;
}
.adjust-coookies-modal_header p.title {
    margin-bottom: 20px;
    color: var(--primary);
    font-size: 22px;
}
.adjust-coookies-modal_header .btn-fechar-modal {
    background: var(--primary);
    cursor: pointer;
    height: 30px;
    color: #fff;
    width: 30px;
    min-width: 30px;
    padding: 0;
    border-radius: 15px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.adjust-coookies-modal_topo {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;
}
.adjust-cookies-modal_body .cookie {
    margin: 20px 0;
    border: 1px solid #dedede;
    padding: 20px;
}
.adjust-cookies-modal_body .cookie p.subtitle {
    font-size: 18px;
    display: block;
}
.active-desnecessario {
    width: 100px;
}
.adjust-cookies-modal_body .cookie .cookie-active {
    display: block;
    font-size: 16px;
    color: #999;
    font-weight: 700;
    margin: 10px 0;
    display: flex;
    align-items: center;
}
.adjust-cookies-modal_body .cookie span input {
    margin-left: 10px;
}
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    transform: scale(0.7);
    margin: 0;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
input:checked + label .slider {
    background-color: var(--primary);
}
input:focus + label .slider {
    box-shadow: 0 0 1px var(--primary);
}
input:checked + label .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
.slider.round {
    border-radius: 34px;
}
.slider.round:before {
    border-radius: 50%;
}
@media (max-width: 767.98px) {
    .adjust-cookies-modal_content {
        padding: 20px;
        width: 80%;
    }
    .adjust-cookies-modal p {
        display: none;
    }
    .accept-cookies {
        width: 90vw;
        max-width: unset;
        right: 5vw;
        bottom: 5vw;
    }
}
@media (min-width: 768px) and (max-width: 1299.98px) {
    .adjust-cookies-modal_content {
        width: 90%;
    }
}
@media (min-width: 1300px) and (max-width: 1800px) {
    .adjust-cookies-modal_content {
        width: 75%;
    }
}
